@tailwind base;
@tailwind components;
@tailwind utilities;


html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Montserrat', sans-serif;
}

/* Темный анимированный градиент */
.bg-gradient-animation {
  background: linear-gradient(45deg, #2c003e, #120f4b, #1a004d, #0a1f3e);
  background-size: 300% 300%;
  animation: gradient-animation 10s ease infinite;
}

@keyframes gradient-animation {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}
